/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import QrScanner from 'react-qr-scanner';
import logo from '../components/images/logo.png';
import { useNavigate } from 'react-router-dom';

const Home = () => {

  
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [qrCodeResults, setQrCodeResults] = useState([]);
  const [log, setLog] = useState([]);
  const [student, setStudent] = useState({});
  const navigate = useNavigate();

  const handleGotoStaff = () => {
    navigate("/staff");
  }

  const handleGotoStudent = () => {
    navigate("/student");
  }

  useEffect(() => {
    async function getCameras() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameras(videoDevices);
      } catch (error) {
        console.error('Error getting cameras:', error);
      }
    }
    getCameras();
  }, []);

  useEffect(() => {
    // Clean up log older than 30 hours
    const cleanupLog = setInterval(() => {
      const currentTime = Date.now();
      const newLog = log.filter(entry => (currentTime - entry.timestamp) <= 30 * 60 * 60 * 1000);
      setLog(newLog);
    }, 3600000); // Check every hour

    return () => clearInterval(cleanupLog);
  }, [log]);

  const handleStartScan = () => {
    setScanning(true);
  };

  const handleScanResult = async (result) => {
    if (result) {
      const newLogEntry = { result, timestamp: Date.now() };
      setLog(prevLog => [...prevLog, newLogEntry]);
      try {
        console.log(result.text);
        // Only post the result if it exists in the log
        //const logContainsResult = log.some(entry => entry.result === result);
        //if (logContainsResult) {
          console.log('hi')
          const url = 'https://early.school:8001/api/attendance/student/fromsmartcard-in/?hash='+result.text;
          const requestData = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(),
          };
          console.log(url);
          const response = await fetch(url, requestData);
          const responseData = await response.json();
          setStudent(responseData);
          console.log(responseData);
        //}
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCameraChange = async (event) => {
    const deviceId = event.target.value;
    const camera = cameras.find((cam) => cam.deviceId === deviceId);

    if (camera) {
      setSelectedCamera(camera);
      setScanning(true);
    }
  };
  //set size for the logo
  const logoStyle ={
    height: '80px',
    width: '200px'
  }
  
  const backImage = {
    backgroundImage: 'url({logo})'
  }

  return (
    <div className="App container-md text-center mt-4" style={backImage}>
      <img src={logo} style={logoStyle} className='mb-2' alt='' />
      <h1 className='h1 text-primary'>EarlySchool <span className='text-danger'>Secure</span> ScanID</h1>
      <hr></hr>
      <div className='py-5 px-5'>
        <button onClick={handleGotoStaff} className='btn btn-outline-primary my-5 mx-5 px-5'>Check In/Out Staff</button>
        <button onClick={handleGotoStudent} className='btn btn-outline-primary my-5 mx-5 px-5'>Check In/Out Student</button>
      </div>
    </div>
  );
}

export default Home;