/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import QrScanner from 'react-qr-scanner';
import logo from '../components/images/logo.png';
import avatar from '../components/images/avatar.jpg';

const Staff = () => {
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [qrCodeResults, setQrCodeResults] = useState([]);
  const [log, setLog] = useState([]);
  const [student, setStudent] = useState({});


  useEffect(() => {
    async function getCameras() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameras(videoDevices);
      } catch (error) {
        console.error('Error getting cameras:', error);
      }
    }
    getCameras();
  }, []);

  useEffect(() => {
    // Clean up log older than 30 hours
    const cleanupLog = setInterval(() => {
      const currentTime = Date.now();
      const newLog = log.filter(entry => (currentTime - entry.timestamp) <= 30 * 60 * 60 * 1000);
      setLog(newLog);
    }, 3600000); // Check every hour

    return () => clearInterval(cleanupLog);
  }, [log]);

  const handleStartScan = () => {
    setScanning(true);
  };

  const handleScanResult = async (result) => {
    if (result) {
      const newLogEntry = { result, timestamp: Date.now() };
      setLog(prevLog => [...prevLog, newLogEntry]);
      try {
        console.log(result.text);
        // Only post the result if it exists in the log
        //const logContainsResult = log.some(entry => entry.result === result);
        //if (logContainsResult) {
          const url = 'https://api.early.school/api/attendance/student/fromsmartcard-in/?hash='+result.text;
          const requestData = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(),
          };
          console.log(url);
          const response = await fetch(url, requestData);
          const responseData = await response.json();
          setStudent(responseData);
          console.log(responseData);
        //}
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCameraChange = async (event) => {
    const deviceId = event.target.value;
    const camera = cameras.find((cam) => cam.deviceId === deviceId);

    if (camera) {
      setSelectedCamera(camera);
      setScanning(true);
    }
  };
  //set size for the logo
  const logoStyle ={
    height: '80px',
    width: '200px'
  }
  return (
    <div className="App container-md text-center mt-4">
      <img src={logo} style={logoStyle} className='mb-2' alt='' />
      <h1 className='h1 text-primary'>EarlySchool Student <span className='text-danger'>Secure</span> ScanID</h1>
      <div>
        <h4 className='h4 text-secondary'>Available Cameras:</h4>
        <select className='form-select'
          value={selectedCamera ? selectedCamera.deviceId : ""}
          onChange={handleCameraChange}
        >
          <option value="">Select a camera</option>
          {cameras.map(camera => (
            <option key={camera.deviceId} value={camera.deviceId}>
              {camera.label || `Camera ${cameras.indexOf(camera) + 1}`}
            </option>
          ))}
        </select>
        <div className='my-4'>
          {selectedCamera && scanning ? (
            <QrScanner  style={{height:'20rem'}}
              constraints={{
                video: {
                  deviceId: selectedCamera.deviceId,
                },
              }}
              onScan={handleScanResult}
              onError={error => console.error('QR Scanner Error:', error)}
              key={selectedCamera.deviceId}
            />
          ) : null}
        </div>
        {/*!scanning && (
          <button onClick={handleStartScan} className='btn btn-outline-primary my-3'>Start Scan</button>
        )*/}
      </div>
      {student != {} ? 
        <div className='my-5 mx-5'>
        { student.profile_image ? <img src={student.profile_image} width={50} height={50} /> : <img src={avatar} width={50} height={50} /> }
        <span>
          <b>
          { student.first_name } { student.last_name } 
          </b>
        </span>
        <br></br><span> has <b>{ student.action } </b></span>
      </div>
      : null }
    </div>
  );
}

export default Staff;