import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Student from "../pages/Student";
import Staff from "../pages/Staff";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/student" element={<Student />} />
          <Route path="/staff" element={<Staff />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;